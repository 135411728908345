<template>
  <div class="px-5 pt-10 text-center">
      <!-- <img
       :src="
          this.user.avatar.uri
          ? this.user.avatar.uri
          : '/assets/media/bipart/logo-default.svg'
        "
        class="circle w-75 border-3 border-white bg-white p-8"
        v-if="this.user.avatar"
      /> -->
<!--      <div class="circle border-3 border-white bg-white p-4 mx-auto" style="height:115px; width: 115px; background: url('./assets/media/bipart/logo-default.svg') center no-repeat; background-size:cover !important;"></div>-->
      <div class="circle border-3 border-white bg-white p-4 mx-auto avatar" :style="$imageOptions.styleSettings({width: 115, height: 115, userImage: (this.user.avatar && this.user.avatar.uri ? this.user.avatar.uri : $imageOptions.defaultAvatarUrl)})"></div>
        <!-- ultima modifica -->
<!--      <img v-if="user.avatar" :src="user.avatar.uri ? user.avatar.uri : '/assets/media/bipart/logo-default.svg' " class="circle w-75 border-3 border-white bg-white p-4"/>-->
<!--      <img v-else src="/assets/media/bipart/logo-default.svg" class="circle w-75 border-3 border-white bg-white p-4"/>-->
      <div class="mt-10">
      <!-- Aggiungere n. gruppi <span class="h2 text-primary ml-10">{{ nr_groups }}</span> gruppi -->
      <router-link
        to="/personal-space"
       class="text-white rounded mb-2 btn btn-outline-white"
      style="padding: 7px 10px; display: block;"
      >
        <span
          class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
          >{{$t("MAINMENU.PSPROFILE")}}
        </span>
      </router-link>
      <router-link
        to="/personal-space-activities"
       class="text-white rounded mb-2 btn btn-outline-white"
      style="padding: 7px 10px; display: block;"
      >
        <span
          class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
          >{{$t("MAINMENU.PSACTIVITIES")}}
        </span>
      </router-link>
      <router-link
        to="/personal-space-account"
       class="text-white rounded mb-2 btn btn-outline-white"
      style="padding: 7px 10px; display: block;"
      >
        <span
          class="font-weight-bolder font-size-base text-uppercase font-weight-bolder"
          >{{$t("MAINMENU.PSSETTINGS")}}
        </span>
      </router-link>
      </div>

  </div>
</template>

<script>

export default {
  name: "PersonalMenu",
  components: {

  },
  props: ["user"],
  data() {
    return{
      // urlAvatar: "",
    }
  },
  created() {
    // console.log("user avatar: ", this.user.avatar.uri)
    // this.urlAvatar = this.user.avatar && this.user.avatar.uri ? this.user.avatar.uri : './assets/media/bipart/logo-default.svg';
  },
  mounted() {
    console.log("user: ", this.user);
  }
};
</script>

<style scoped>
.avatar {
  background-size: cover !important;
}
</style>
