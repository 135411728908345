<template>
  <div class="row justify-content-between h-100">
    <div
      class="d-none d-lg-block col-lg-2 col-xl-2 justify-content-between align-items-center bg-primary text-white"
    >
      <PersonalMenu :user="user"></PersonalMenu>
    </div>
    <div class="col-lg-9 col-xl-9">
      <div class="d-flex flex-column">
        <div class="row align-items-center mt-10">
          <div class="col-12 d-flex justify-content-start">
            <div class="d-flex flex-column px-10">
              <h1 class="card-label h1 font-weight-bolder">
                {{ $t("PSACCOUNT.PS") }}
              </h1>
              <h2 class="card-label h3 font-weight-bold">
                {{ $t("PSACCOUNT.AS") }}
              </h2>
            </div>
          </div>
        </div>
        <div class="row align-items-center my-10 px-10">
          <div class="col-xl-12">
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="d-flex align-items-center">
                  <div class="d-flex flex-column mr-auto">
                    <h2
                      class="font-size-h2 text-dark font-weight-bolder mb-1 mr-10"
                      v-if="user.name"
                    >
                      {{ user.name }} {{ user.surname }}
                    </h2>
                    <h2
                      class="font-size-h2 text-dark font-weight-bolder mb-1 mr-10"
                      v-else
                    >
                      {{ $t("PSACCOUNT.ANONIMO") }}
                    </h2>
                  </div>
                  <div class="card-toolbar">
                    <div
                      class="btn btn-light-primary disabled font-weight-bolder mr-2 mt-2"
                      v-if="user.profilePublic"
                    >
                      {{ $t("PSACCOUNT.TUOPROFILO") }}
                      {{ $t("PSACCOUNT.PUBBLICO") }}
                    </div>
                    <div
                      v-else
                      class="btn btn-light-danger disabled font-weight-bolder mr-2 mt-2"
                    >
                      {{ $t("PSACCOUNT.TUOPROFILO") }}
                      {{ $t("PSACCOUNT.PRIVATO") }}
                    </div>                    
                    <router-link class="btn btn-primary text-uppercase font-weight-bolder mr-2 pr-2 mt-2" to="/personal-space-account-edit"> <i class="fas fa-edit pb-1 text-white"></i></router-link>
                    <a
                      class="btn btn-primary text-uppercase font-weight-bolder pr-2 mt-2"
                      @click.prevent="alertDelete"
                    >
                      <i class="fas fa-trash pb-1 text-white"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-12">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.USERNAME")
                    }}</label>
                    <b-form-input
                      id="input-username"
                      class="bg-default text-primary font-weight-bolder"
                      type="text"
                      required
                      disabled
                      :value="user.username"
                      v-bind:placeholder="$t('PSACCOUNT.USERNAMENONPRESENTE')"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.EMAIL")
                    }}</label>
                    <b-form-input
                      id="input-email"
                      class="bg-default text-primary font-weight-bolder"
                      type="email"
                      required
                      disabled
                      :value="user.email"
                      v-bind:placeholder="$t('PSACCOUNT.EMAILNONPRESENTE')"
                    ></b-form-input>
                  </div>
                  <div class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.PASSWORD")
                    }}</label>
                    <b-form-input
                      id="input-password"
                      class="bg-default text-primary font-weight-bolder"
                      type="password"
                      required
                      disabled
                      value="*****"
                      placeholder="*****"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div class="card card-custom card-shadow mb-3">
              <div class="card-body">
                <div class="row">
                  <div class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.NOME")
                    }}</label>
                    <b-form-input
                      id="input-name"
                      class="bg-default text-primary font-weight-bolder"
                      type="text"
                      required
                      disabled
                      :value="user.name"
                      v-bind:placeholder="$t('PSACCOUNT.NOMENONPRESENTE')"
                    ></b-form-input>
                  </div>
                  <div class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.COGNOME")
                    }}</label>
                    <b-form-input
                      id="input-surname"
                      class="bg-default text-primary font-weight-bolder"
                      type="text"
                      required
                      disabled
                      :value="user.surname"
                      v-bind:placeholder="$t('PSACCOUNT.COGNOMENONPRESENTE')"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="user.mobile || user.taxcode"
              class="card card-custom card-shadow mb-3"
            >
              <div class="card-body">
                <div class="row">
                  <div v-if="user.mobile" class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.PHONE")
                    }}</label>
                    <b-form-input
                      id="input-name"
                      class="bg-default text-primary font-weight-bolder"
                      type="text"
                      required
                      disabled
                      :value="user.mobile"
                      v-bind:placeholder="$t('PSACCOUNT.PHONENONPRESENTE')"
                    ></b-form-input>
                  </div>
                  <div v-if="user.taxcode" class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.TAXCODE")
                    }}</label>
                    <b-form-input
                      id="input-surname"
                      class="bg-default text-primary font-weight-bolder"
                      type="text"
                      required
                      disabled
                      :value="user.taxcode"
                      v-bind:placeholder="$t('PSACCOUNT.TAXCODENONPRESENTE')"
                    ></b-form-input>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-if="user.citizenship"
              class="card card-custom card-shadow mb-3"
            >
              <div class="card-body">
                <div class="row">
                  <div v-if="user.citizenship" class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.CITIZENSHIP")
                    }}</label>
                    <div class="d-flex bg-default text-primary font-weight-bolder form-control" style="gap: 15px;background-color: #F3F6F9;opacity: 1;">
                      <img :src="user.citizenship.flag.uri" style="width: 25px;">
                      <span>{{ user.citizenship.name }}</span>
                    </div>
                  </div>  
                  <div v-if="user.residenceCity" class="col-xl-6">
                    <label class="font-weight-bolder">{{
                      $t("PSACCOUNT.RESIDENCECITY")
                    }}</label>
                       <b-form-input
                        id="input-residence-city"
                        class="bg-default text-primary font-weight-bolder"
                        type="text"
                        required
                        disabled
                        :value="user.residenceCity"    
                      ></b-form-input>
                  </div>  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-none d-sm-block col-1 justify-content-between align-items-center"
    >
      <div class="d-flex flex-column mt-10 mr-5 align-items-center">
        <router-link to="">
          <a
            href="#"
            class="mb-10 btn btn-icon w-auto d-flex btn-lg px-2"
            @click.prevent="alertInfo"
            id="pop-info"
          >
            <span class="symbol symbol-rounded">
              <span
                class="symbol-label font-size-h3 font-weight-bold bg-primary text-white"
              >
                <span class="svg-icon svg-icon-white svg-icon-2x">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24px"
                    height="24px"
                    viewBox="0 0 24 24"
                    version="1.1"
                  >
                    <g
                      stroke="none"
                      stroke-width="1"
                      fill="none"
                      fill-rule="evenodd"
                    >
                      <rect x="0" y="0" width="24" height="24" />
                      <circle
                        fill="#000000"
                        opacity="0.3"
                        cx="12"
                        cy="12"
                        r="10"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="10"
                        width="2"
                        height="7"
                        rx="1"
                      />
                      <rect
                        fill="#000000"
                        x="11"
                        y="7"
                        width="2"
                        height="2"
                        rx="1"
                      />
                    </g>
                  </svg>
                </span>
              </span>
            </span>
          </a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
//import ProcessCardPreview from "@/view/pages/process/partials/ProcessCardPreview.vue";
// import GroupCardPreview from "@/view/pages/groups/partials/GroupCardPreview";
import PersonalMenu from "@/view/layout/common/PersonalMenu.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import { RouterLink } from "vue-router";

export default {
  name: "personalSpaceAccount",
  components: {
    PersonalMenu,
    RouterLink
},
  data() {
    return {
      processes: [],
      groups: [],
      user: [],
    };
  },
  computed: {
    ...mapGetters(["currentUser"]),
    getGenderLabel() {
      return this.user.gender == "M"
        ? this.$t("PS.MASCHIO")
        : this.$t("PS.FEMMINA");
    },
    getTermsConditionsLabel() {
      return this.user.termsConditionsAccepted == true ? " checked " : "";
    },
    getPrivacyLabel() {
      return this.user.privacyPolicyAccepted == true ? " checked " : "";
    },
    getNewsletterLabel() {
      return this.user.newsletterAccepted == true ? " checked " : "";
      // return 'checked';
    },
    getPublicPrivateLabel() {
      return this.user.profilePublic == false ? " checked " : "";
      // return '';
    },
  },
  mounted() {
    // this.getScenarios();
    // this.getChallenges();
    //this.getProcesses();
    // this.getGroups();
    // this.getOrganizations();

    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title:
          this.currentUser.name || this.currentUser.surname
            ? `${this.currentUser.name} ${this.currentUser.surname}`
            : this.currentUser.username,
      },
    ]);
  },
  created() {
    this.getUser();
  },
  methods: {
    getUser() {
      this.$isLoading(true);
      return new Promise((resolve, reject) => {
        var endpoint = this.generateUrl(
          "users-expanded/" + this.currentUser.id,
          true
        );
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            this.user = res.data;
            console.log("this.user", this.user);
            this.$isLoading(false);
            resolve();
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
    alertDelete() {
      Swal.fire({
        title: this.$t("PSACCOUNT.ELIMINAACCOUNTTITLE"),
        html: this.$t("PSACCOUNT.ELIMINAACCOUNT"),
        icon: "error",
        confirmButtonClass: "btn btn-danger",
      });
    },
    alertInfo() {
      Swal.fire({
        title: this.$t("HOME.ALERT2TITLE"),
        html: this.$t("HOME.ALERT2"),
        icon: "warning",
        confirmButtonClass: "btn btn-warning",
      });
    },
  },
};
</script>
